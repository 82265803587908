import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { LocalTaxi, LocationCity, Nightlife } from '@mui/icons-material';
import { Diamond } from '@mui/icons-material';
import { Liquor } from '@mui/icons-material';
import { Flatware } from '@mui/icons-material';
import Typography from '@mui/material/Typography';

export default function CustomizedTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="rgb(156, 175, 136)"
        >
          <b>13:30 pm</b>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ color: 'rgb(156, 175, 136)', background: 'rgba(0,0,0,0.5)' }}>
            <LocationCity />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h8" component="span">
          <b>Arrive</b>
          </Typography>
          <Typography sx= {{ fontSize: '12px'}}></Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="rgb(156, 175, 136)"
        >
          <b>14:00 pm</b>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ color: 'rgb(156, 175, 136)', background: 'rgba(0,0,0,0.5)' }}>
            <Diamond />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h8" component="span">
          <b>Ceremony</b>
          </Typography>
          <Typography sx= {{ fontSize: '12px'}}>Time to tie the knot!</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="rgb(156, 175, 136)"
        >
          <b>14:30 pm</b>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ color: 'rgb(156, 175, 136)', background: 'rgba(0,0,0,0.5)' }}>
            <Liquor />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h8" component="span">
          <b>Drinks Reception</b>
          </Typography>
          <Typography sx= {{ fontSize: '12px'}}>Lets get the drinks flowing</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="rgb(156, 175, 136)"
        >
          <b>16:15 pm</b>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ color: 'rgb(156, 175, 136)', background: 'rgba(0,0,0,0.5)' }}>
            <Flatware />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h8" component="span">
            <b>Wedding Breakfast</b>
          </Typography>
          <Typography sx= {{ fontSize: '12px'}}>Food and speeches!</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="rgb(156, 175, 136)"
        >
          <b>19:30 pm</b>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ color: 'rgb(156, 175, 136)', background: 'rgba(0,0,0,0.5)' }}>
            <Nightlife />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h8" component="span">
            <b>Evening Reception</b>
          </Typography>
          <Typography sx= {{ fontSize: '12px'}}>Everyone on the dancefloor!</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="rgb(156, 175, 136)"
        >
          <b>01:00 am</b>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ color: 'rgb(156, 175, 136)', background: 'rgba(0,0,0,0.5)' }}>
            <LocalTaxi />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h8" component="span">
            <b>Taxis</b>
          </Typography>
          <Typography sx= {{ fontSize: '12px'}}>Time to go!</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}